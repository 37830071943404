<template>
  <div class="container">
    <h1>微信支付记录</h1>
    <div class="filter-box">
      <div class="left-box">
        <div class="item">
          <el-input v-model="params.KeyWords" size="small" placeholder="关键字" clearable />
        </div>
        <div class="item">
          <el-button type="primary" size="small" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button type="default" size="small" icon="el-icon-close" @click="handleCleanFilter">清除筛选</el-button>
        </div>
      </div>
      <div class="right-box">
        <el-button type="primary" size="small" icon="el-icon-download" @click="handleExport">导出 Excel</el-button>
      </div>
    </div>
    <div class="table-box">
      <el-table :data="items" border style="width: 100%">
        <el-table-column prop="ID" label="支付ID" />
        <el-table-column prop="openid" label="OpenID" />
        <el-table-column prop="phone" label="手机号" width="150" />
        <el-table-column prop="createTime" label="支付创建时间" width="200">
          <template #default="scope">
            {{ dateFormat(scope.row.createTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="payTime" label="支付完成时间" width="200">
          <template #default="scope">
            {{ dateFormat(scope.row.payTime) }}
          </template>
        </el-table-column>
        <el-table-column prop="type" label="用途" width="150">
          <template #default="scope">
            {{ typeFormat(scope.row.type) }}
          </template>
        </el-table-column>
        <el-table-column prop="amount" label="金额" width="150" />
      </el-table>
    </div>
    <div style="margin:20px auto; text-align:center">
      <el-pagination :page-size="params.Take" :total="totalCount" background layout="total, prev, pager, next"
        @current-change="handlePageChange" />
    </div>
  </div>
</template>

<script>
  import {
    getPayList
  } from "@/api/trade"
  import {
    formatJsonDate
  } from "../../utils/dateFormat"
  import {
    createFile,
    download
  } from '../../utils/excel'

  export default {
    data() {
      return {
        loading: false,
        params: {
          Skip: 0,
          Take: 20,
          KeyWords: null
        },
        items: [],
        totalCount: 0
      }
    },
    mounted() {
      this.init()
    },
    methods: {
      init() {
        getPayList(this.params.KeyWords, this.params.Skip, this.params.Take)
          .then(res => {
            if (res.success) {
              this.items = res.items
              this.totalCount = res.totalCount
            }
          })
      },
      typeFormat(type) {
        switch (type) {
          case 1:
            return '购买日上券'
          case 2:
            return '充值'
          default:
            return type
        }
      },
      dateFormat(time) {
        return formatJsonDate(time)
      },
      handleFilter() {
        this.params.Skip = 0
        this.init()
      },
      handleCleanFilter() {
        this.params.Skip = 0
        this.params.KeyWords = null
        this.init()
      },
      handleExport() {
        getPayList(this.params.KeyWords, 0, 0)
          .then(res => {
            if (res.success) {
              let exportData = res.items.map(d => {
                return {
                  '支付ID': d.ID,
                  'OpenID': d.openid,
                  '手机号': d.phone,
                  '支付创建时间': this.dateFormat(d.createTime),
                  '支付完成时间': this.dateFormat(d.payTime),
                  '用途': this.typeFormat(d.type),
                  '金额': d.amount
                }
              })

              let columnWidths = [{
                wpx: 250
              }, {
                wpx: 230
              }, {
                wpx: 100
              }, {
                wpx: 110
              }, {
                wpx: 110
              }, {
                wpx: 80
              }, {
                wpx: 80
              }]

              var file = createFile(exportData, columnWidths)
              download(file, '微信支付记录', document)
            }
          })
      },
      handlePageChange(page) {
        this.params.Skip = (page - 1) * this.params.Take
        this.init()
      }
    }
  }
</script>

<style lang="scss" scoped>
  .container {
    .filter-box {
      &:after {
        content: "";
        display: table;
        clear: both;
      }

      .left-box {
        float: left;

        .item {
          float: left;
          margin-right: 10px;
        }
      }

      .right-box {
        float: right;
      }
    }

    .table-box {
      margin-top: 20px;
    }
  }
</style>
