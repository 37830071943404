import request from '@/utils/request'

export function getPayList(keyword, skip, take) {
  return request({
    url: '/Trade/GetPayList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}

export function getTradeList(keyword, skip, take) {
  return request({
    url: '/Trade/GetTradeList',
    method: 'post',
    params: {
      keyword,
      skip,
      take
    }
  })
}
